<template>
  <v-card class="rounded-t-0">
    <v-card-title class="align-start">
      <span>Centers & Branches</span>

      <v-spacer></v-spacer>
    </v-card-title>

    <v-card-text>
      
       <div id="Polar-chart"></div>

    </v-card-text>
  </v-card>
</template>

<script>

import { mdiDotsVertical, mdiTrendingUp, mdiCurrencyUsd } from '@mdi/js'
import { getCurrentInstance } from '@vue/composition-api'
import daskboardserice from '@/service/Dashboard.service'
import moment from 'moment'
export default {

  setup() {
    const ins = getCurrentInstance()?.proxy
    const $vuetify = ins && ins.$vuetify ? ins.$vuetify : null
    const customChartColor = $vuetify.theme.isDark ? '#3b3559' : '#f5f5f5'


    return {

      icons: {
        mdiDotsVertical,
        mdiTrendingUp,
        mdiCurrencyUsd,
      },
    }
  },
   
   
  watch: {
    options: {
      handler() {
        this.chartMap()
      },
      deep: true,
    },
  },
  mounted() {
    this.chartMap()

  },
  methods:{

    async chartMap(){
      const service = new daskboardserice()
      let response = await service.getDaskboard()
      var data12 = response.centere
      const TotalBranches = data12.map((d) => parseFloat(d["total_branches"]))
      const city = data12.map((d) => d["city"])
      var options = {
          series: TotalBranches,
        chart: {
          width: 450,
          type: 'donut',
        },
        colors:['#1bbc9b', '#00e396', '#feb019', '#ffcc99', '#b35900','#00FFFF','#1E90FF', '#191970', '#FF00FF', '#FF1493', '#778899', '#DEB887', '#D2691E','#800000','#66CDAA','#FFEF00','#FFA500','#704214','#E55451','#800000', '#DC143C'],
        labels: city,
        responsive: [{
          breakpoint: 480,
          options: {
            chart: {
              width: 200
            },
            legend: {
              position: 'bottom'
            }
          }
        }]
        };

        var Polar_chart = new ApexCharts(document.querySelector("#Polar-chart"), options);
        Polar_chart.render();
    }
  }
}
</script>
