<template>
  <v-card class="rounded-b-0">
    <v-card-title class="align-start">
      <span>Tissue Category</span>

      <v-spacer></v-spacer>

     
    </v-card-title>

    <v-card-text>
      
     
       <div id="pie-chart"></div>
     
    </v-card-text>
  </v-card>
</template>

<script>

import { mdiDotsVertical, mdiTrendingUp, mdiCurrencyUsd } from '@mdi/js'
import { getCurrentInstance } from '@vue/composition-api'
import daskboardserice from '@/service/Dashboard.service'
import moment from 'moment'
export default {

  setup() {
    


    return {

      icons: {
        mdiDotsVertical,
        mdiTrendingUp,
        mdiCurrencyUsd,
      },
    }
  },
   
   
   watch: {
    options: {
      handler() {
        this.chartMap()
      },
      deep: true,
    },
  },
  mounted() {
    this.chartMap()

  },
  methods:{

    async chartMap(){
      const service = new daskboardserice()
      let response = await service.getDaskboard()
      var data12 = response.tissue_category
      var Optical = data12.Optical
      var Therapeutic = data12.Therapeutic
      const arr =[]
      arr[0]=Optical
      arr[1]=Therapeutic
      
    
      var options = {
          series: arr,
          chart: {
          width: 385,
          type: 'pie',
        },
        colors:['#1bbc9b', '#00e396', '#feb019'],
        labels: ['Optical', 'Therapeutic '],
        responsive: [{
          breakpoint: 480,
          options: {
            chart: {
              width: 200
            },
            legend: {
              position: 'bottom'
            }
          }
        }]
        };

        var pir_chart = new ApexCharts(document.querySelector("#pie-chart"), options);
        pir_chart.render();
    }
  }
}
</script>
